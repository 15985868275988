/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;

const scrollTo = id => () => {
  const el = document.querySelector(id);
  if (el) {
    return window.scrollTo(0, el.offsetTop - 20);
  }
  return false;
};

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 100);
  }
};
