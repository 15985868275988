import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
  if (action.type === 'SET_AFFILIATE') {
    return Object.assign({}, state, {
      affiliate: action.value,
    });
  } else if (action.type === 'SET_ADSENSE') {
    return Object.assign({}, state, {
      adSense: action.value,
    });
  } else if (action.type === 'SET_PADDLE_AFFILIATE_ID') {
    return Object.assign({}, state, {
      paddleAffiliateId: action.value,
    });
  }
  return state;
};

const initialState = { affiliate: null, adSense: null, paddleAffiliateId: null };

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore;
